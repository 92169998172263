import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { parseACF } from '../utils'
import { Layout, Nav, Cart, Image } from '../components'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'
import { sliderContext } from '../components/ImageSlider/SliderProvider'
import { IsMobileContext } from '../context/IsMobileContext'

import cartImage from '../assets/images/cart-image.jpg'

const CartPage = props => {
    const { active, setActive } = useContext(sliderContext)
    const { isMobile } = useContext(IsMobileContext)

    useMount(() => {
        if (active) {
            setActive(false)
        }
    })

    return (
        <>
            <Layout>
                <Wrapper>
                    <Cart />
                    <Nav  type={'store'} slug={'cart'} />
                </Wrapper>
            </Layout>
            {!isMobile && <Image image={cartImage} />}
        </>
    )
}

const Wrapper = styled.div`
    ${tw`w-full h-full flex flex-col`}; 
`


export default CartPage
